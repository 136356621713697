import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';


Swiper.use([Autoplay, Navigation, Pagination]);

let slideshow = null;
let angebotSlider = null;
let slider = null;
let zimmerBilderSlider = null;

if (document.querySelector('.ce_rsce_slideshowStart')){
    slideshow = new Swiper(".ce_rsce_slideshowStart", {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: ".ce_rsce_slideshowStart .swiper-button-next",
            prevEl: ".ce_rsce_slideshowStart .swiper-button-prev",
        },
    });
}

if (document.querySelector('.ce_rsce_angebotSlider')){
    angebotSlider = new Swiper(".ce_rsce_angebotSlider .swiper", {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: true,
        autoplay: {
            delay: 100000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
        },
        navigation: {
            nextEl: ".ce_rsce_angebotSlider .swiper-button-next",
            prevEl: ".ce_rsce_angebotSlider .swiper-button-prev",
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            // when window width is >= 1024px
            1024: {
                slidesPerView: 3,
                spaceBetween: 30,
            }
        }
    });
}



if (document.querySelector('.ce_rsce_slider')){
    slider = new Swiper(".ce_rsce_slider .swiper", {
        slidesPerView: window.innerWidth < 1024 ? 2 : 3,
        spaceBetween: 80,
        loop: true,
        autoplay: {
            delay: 100000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
        },
        navigation: {
            nextEl: ".ce_rsce_slider .swiper-button-next",
            prevEl: ".ce_rsce_slider .swiper-button-prev",
        },
    });
}

if (document.querySelector('.ce_rsce_zimmer')){
    zimmerBilderSlider = new Swiper(".ce_rsce_zimmer .swiper", {
        slidesPerView: 1,
        spaceBetween: 80,
        loop: true,
        autoplay: {
            delay: 100000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
        },
        navigation: {
            nextEl: ".ce_rsce_zimmer .swiper-button-next",
            prevEl: ".ce_rsce_zimmer .swiper-button-prev",
        },
    });
}



