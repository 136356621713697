const zimmer = document.querySelectorAll('.ce_rsce_zimmer');

if (zimmer.length > 0){
   zimmer.forEach(zimmer => {
        let ausstattungen = zimmer.querySelectorAll('.ausstattung');
        ausstattungen.forEach(ausstattung => {
            ausstattung.addEventListener('click', function(){
                if (ausstattung.classList.contains('active')){
                    ausstattung.classList.remove('active');
                    ausstattung.parentElement.querySelector('.beschreibung').classList.remove('active');
                } else {
                    ausstattung.classList.add('active');
                    ausstattung.parentElement.querySelector('.beschreibung').classList.add('active');
                }
            })
        })
   })
}