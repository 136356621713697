const menuButton = document.querySelector('.container-inner.menu');
const navContainer = document.querySelector('.container.navigation');

menuButton.addEventListener('click', function(){
    if (menuButton.classList.contains('open')){
        menuButton.classList.remove('open');
        navContainer.classList.remove('open');
    } else {
        menuButton.classList.add('open');
        navContainer.classList.add('open');
    }
})




const subMenu = document.querySelectorAll('li.submenu');

subMenu.forEach(submenu => {
    submenu.addEventListener('click', function(event){
        if (submenu.classList.contains('open')){
            submenu.classList.remove('open');
            //showNav();
        } else {
            submenu.classList.add('open');
            //hideNav();
        }
    })
})

const liSibling = document.querySelectorAll('li.sibling')

function hideNav() {
    liSibling.forEach(submenu => {
        if (submenu.classList.contains('open')){
         
        } else {
            
            submenu.style.display = 'none';
        }
    })
}

function showNav() {
    liSibling.forEach(submenu => {
        if (submenu.classList.contains('open')){
        
        } else {
            submenu.style.display = 'block';
        }
    })
}